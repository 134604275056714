import { defineStore } from 'pinia';
import Client from "@/utils/client";

const sharedState = {
    lists: [],
    currentList: {
        id: null,
        name: null,
        user_owner: false,
        profiles: []
    },
    currentProfile: {
        id: null,
        name: null
    }
};

export default defineStore('ListStore', {
    state: () => sharedState,
    actions: {
        async addProfile(id, params) {
            const response = await Client.post(`/profile/lists/${id}/add_to_list`, params);
            const index = this.lists.findIndex(o=>o.id===id);
            
            this.lists[index] = await response.json();
        },
        async removeProfile(id, params) {
            const response = await Client.post(`/profile/lists/${id}/remove_from_list`, params);

            if (CURRENT_PAGE == 'lists#show') {
                const index = this.currentList.profiles.findIndex(o=>o.id===id);
                this.currentList.profiles.splice(index, 1);
            } else {
                const index = this.lists.findIndex(o=>o.id===id);
                this.lists[index] = await response.json();
            }
        },
        async fetchList(id) {
            const response = await Client.get(`/profile/lists/${id}`);
            this.currentList = await response.json();
        },
        async fetchLists() {
            const response = await Client.get('/profile/lists/');
            this.lists = await response.json();
        },
        async createList(params) {
            const response = await Client.post('/profile/lists/', params);
            const list = await response.json();

            this.lists.push(list);

            if (this.currentProfile.id) {
                await this.addProfile(list.id, {
                    profile_id: this.currentProfile.id
                });
            }
        },
        async renameList(id=null, params) {
            const url = `/profile/lists/${id}`
            const response = await Client.patch(url, params);
            const data = await response.json();

            if (CURRENT_PAGE == 'lists#index') {
                const index = this.lists.findIndex(o=>o.id==id);
                this.lists[index] = data;
            } else {
                this.currentList.name = data.name;
            }
        },
        async deleteList(id=null) {
            const url = `/profile/lists/${id}`;
            await Client.delete(url);

            if (CURRENT_PAGE == 'lists#index') {
                const index = this.lists.findIndex(o=>o.id===id);
                this.lists.splice(index, 1);
            }
        },
        setCurrentList(list) {
            this.currentList.id = list.id;
            this.currentList.name = list.name;
        },
        setCurrentProfile(profile) {
            this.currentProfile.id = profile.id;
            this.currentProfile.name = profile.full_name;
        }
    }
})